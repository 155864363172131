import { Box, CircularProgress } from "@mui/material";
import { ColorRing } from "react-loader-spinner";

export default function SavingIndicator() {
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
            className="loading-bg">
            <CircularProgress />
        </Box>
    )
}