import { OAuthCredential, OAuthProvider, ParsedToken, User, UserCredential, getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

interface AuthProviderProps {
    children: ReactNode
}

export interface IAuth {
    currentUser?: User | null,
    userRole?: string | null,
    signIn(email: string, password: string): Promise<UserCredential>,
    signOut(): Promise<void>,
    signInWithMicrosoft(tenant: string): Promise<UserCredential>,
    getUserClaims(user: User): Promise<ParsedToken>,
}

function signIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
}

async function signOut() {
    auth.signOut();
}

async function signInWithMicrosoft(tenant: string): Promise<UserCredential>  {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
        prompt: 'consent',
        login_hint: 'user@alsabahmediagroup.com',
        tenant: tenant,
      });

    // provider.addScope('mail.read');
    // provider.addScope('calendars.read');

    const auth = getAuth();
    return await signInWithPopup(auth, provider);
}

async function getUserClaims(user: User): Promise<ParsedToken> {
    // If refresh is set to true, a refresh of the id token is forced.
    const idTokenResult = await user?.getIdTokenResult(true);

    return idTokenResult?.claims;
}

const AuthContext = React.createContext<IAuth>({
    currentUser: null,
    userRole: null,
    signIn: signIn,
    signOut: signOut,
    signInWithMicrosoft: signInWithMicrosoft,
    getUserClaims: getUserClaims,
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider(props: AuthProviderProps) {
    const [currentUser, setCurrentUser] = useState<User | null>();
    const [userRole, setUserRole] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(async (user) => {
            setCurrentUser(user);

            const idTokenResult = await user?.getIdTokenResult(true);
            // .then((idTokenResult) => {
            if (idTokenResult && idTokenResult.claims && idTokenResult.claims["role"]) {
                setUserRole(idTokenResult?.claims["role"] as string);
            }
            // });

            setLoading(false)
        })

        return unsub;
    }, [])

    const value: IAuth = {
        currentUser,
        userRole,
        signIn,
        signOut,
        signInWithMicrosoft,
        getUserClaims,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && props.children}
        </AuthContext.Provider>
    )
}