import {
    doc,
    collection,
    updateDoc,
    query,
    onSnapshot,
    where,
    orderBy,
    Timestamp,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import Visit, { VisitConverter } from "../../models/Visit";
import { ColorRing } from "react-loader-spinner";
import DeleteDialog from "../../components/delete-dialog";
import { useTranslation } from "react-i18next";
import { DateTime, Settings } from "luxon";
import { DataGrid, GridEventListener, GridRenderCellParams, GridRowsProp, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid";
import { FilterBox } from "../../components/FilterBox";
import { SearchBox } from "../../components/SearchBox";
import SavingIndicator from "../../components/saving-indicator";
import VisitorLogForm from "../../components/VisitorLogForm";
import { VisitType } from "../../enums/VisitType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faCrown, faPerson, faPersonWalking, faUnlink, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { escapeRegExp } from "@mui/x-data-grid/utils/utils";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
export default function VisitorsLogPage() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Visit[]>([]);
    const [filteredData, setFilteredData] = useState<Visit[]>([]);
    const [searchText, setSearchText] = useState("");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showProgress, setShowProgress] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [target, setTarget] = useState<Visit | null>(null);

    const handleDelete = async (row: any) => {
        const visitRef = doc(collection(db, "visits"), row.id);
        await updateDoc(visitRef, { deleted: true });
        setShowDeleteDialog(false);
        setShowForm(false);
    };

    const handleClose = () => setShowForm(false);

    const applyFilter = (
        countryId: string,
        universityId: string,
        collegeId: string
    ) => {
        // We need to save filters for later
        // setCountryFilter(countryId);
        // setUniversityFilter(universityId);
        // setCollegeFilter(collegeId);

        if (data) {
            const items = data.filter((course: Visit) => {
                // const collegeFilter = collegeId ? course.college?.id == collegeId : true;
                // const universityFilter = universityId ? course.college?.university?.id == universityId : true;
                // const countryFilter = countryId ? course.college?.university?.country?.id == countryId : true;

                // return collegeFilter && universityFilter && countryFilter;

                return true;
            });

            setFilteredData(items);
        }
    };

    const handleRowClick: GridEventListener<"rowClick"> = (
        params,
        event,
        details
    ) => {
        setTarget(params.row);
        setSelectedRow(params.row);
        setShowForm(true);
    };

    const buildLeadingIcon = (params: GridRenderCellParams<any, any>) => {
        var icon: IconDefinition = faUnlink
        var color: string = 'text-primary'

        switch (params.value!) {
            case VisitType.vip:
                icon = faCrown
                break

            case VisitType.interview:
                icon = faCircleUser
                break

            case VisitType.visitor:
                icon = faPerson
                break

            case VisitType.walkin:
                icon = faPersonWalking
                break
        }

        if (params.row.checkOutDate) {
            color = 'green'
        } else if (params.row.checkInDate) {
            color = 'red'
        }

        return <FontAwesomeIcon icon={icon} style={{ color: `${color}`}} size="xl" />
    }

    const searchData = (phrase: string) => {
        setSearchText(phrase);
        const searchRegex = new RegExp(escapeRegExp(phrase), 'i');
        const filteredRows = data.filter((row: any) => {
            // return Object.keys(row).some((field: any) => {
            return searchRegex.test(row['name'].toString())
            // });
        });

        setFilteredData(filteredRows)
    }

    useEffect(() => {
        Settings.defaultZone = "Asia/Kuwait";

        setIsLoading(true);

        let dt = DateTime.fromISO(
            DateTime.now().setZone("Asia/Kuwait").toFormat("yyyy-MM-dd")
        );

        const q = query(
            collection(db, "visits").withConverter(VisitConverter),
            where("checkInDate", "!=", null),
            where("deleted", "==", false),
            orderBy("date")
        );

        if (q) {
            const unsubscribe = onSnapshot(q, async (querySnapshot) => {
                let itemsArr: any = [];

                const promises = querySnapshot.docs.map(async (newDoc: any) => {
                    const newVisitor = { ...newDoc.data(), id: newDoc.id };
                    return newVisitor;
                });

                var newData = await Promise.all(promises);

                itemsArr.push(...newData);

                setData(itemsArr);
                setFilteredData(itemsArr);

                setLoading(false)
            });

            return () => unsubscribe();
        }
    }, [i18n.language]);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box component='h2' sx={{ mb: 4 }}>{t("visitors_log_title")}</Box>
                {/* <Box sx={{ display: 'flex', float: 'end', mb: 3, py: 1, px: 3, border: 'solid 2px #74b3ce', borderRadius: 50, }}>
                </Box> */}
            </Box>

            <div className="actions align-items-center" style={{ transition: 'padding-bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), 0.3s cubic-bezier(0.23, 1, 0.32, 1)', paddingBottom: '0px' }}>
                <div className="filters-container">
                    <SearchBox onChange={searchData} />
                    <FilterBox />
                    <div className="flex-grow"></div>
                    <Box sx={{ display: 'flex', float: 'end' }}>
                    </Box>
                </div>
            </div>

            <DataGrid
                loading={loading}
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton',
                    },
                }}
                disableColumnMenu={true}
                rows={filteredData as unknown as GridRowsProp[]}
                columns={[
                    {
                        field: "category",
                        headerName: "",
                        headerAlign: "center",
                        align: "center",
                        resizable: false,
                        width: 80,
                        renderCell: buildLeadingIcon,
                    },
                    {
                        field: "name",
                        headerName: t("name_column_header"),
                        headerAlign: "center",
                        flex: 1,
                        align: "right",
                        resizable: false,
                    },
                    // { field: 'company', headerName: t("company_column_header"), headerAlign: 'center', flex: 1, align: 'right', resizable: false, },
                    {
                        field: "host",
                        headerName: t("host_column_header"),
                        headerAlign: "center",
                        flex: 1,
                        align: "right",
                        resizable: false,
                    },
                    {
                        field: "checkInDate",
                        headerName: t("checkindate_column_header"),
                        headerAlign: "center",
                        width: 150,
                        align: "right",
                        resizable: false,
                        valueGetter: (value?: Date) => {
                            return value 
                                ? DateTime.fromJSDate(value).toFormat("yyyy/MM/dd h:mm a") 
                                : '';
                        },
                    },
                    {
                        field: "checkOutDate",
                        headerName: t("checkoutdate_column_header"),
                        headerAlign: "center",
                        width: 150,
                        align: "right",
                        resizable: false,
                        valueGetter: (value?: Date) => {
                            return value
                                ? DateTime.fromJSDate(value).toFormat("yyyy/MM/dd h:mm a")
                                : '';
                        },
                    },
                ]}
                sx={{
                    fontFamily: "Zain",
                    fontSize: "0.9rem",
                    ".MuiDataGrid-cell:focus": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-row:hover": {
                        cursor: "pointer",
                    },
                }}
                onRowClick={handleRowClick}
            />

            <Dialog
                fullWidth
                maxWidth='lg'
                fullScreen={fullScreen}
                open={showForm}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: { direction: 'rtl', textAlign: 'start', p: '16px', paddingTop: '60px' }
                }}
            >
                <DialogTitle>{target?.name}</DialogTitle>
                <DialogContent>
                <VisitorLogForm
                    target={target as Visit}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('close_button_label')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}