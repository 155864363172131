import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./logo.png";
import { IAuth, useAuth } from "./contexts/AuthContext";
import { AuthError, OAuthCredential, User, UserCredential } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useLayoutDirection } from "./LayoutDirectionProvider";
import MSLogin from "./components/MSLogin";

export const Login = () => {
    const { getUserClaims, currentUser } = useAuth() as IAuth;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isRTL } = useLayoutDirection();

    const [notice, setNotice] = useState("");
      
    // const getUserClaims = async (user: User) => {

    //     // If refresh is set to true, a refresh of the id token is forced.
    //     const idTokenResult = await user?.getIdTokenResult(true);

    //     return idTokenResult?.claims;
    // }

    const handleError = (msg: string) => {
        setNotice(msg);
    }

    const handleSignin = async (credential: UserCredential) => {
        if (credential) {
            // const claims = await getUserClaims(credential.user);
            // if (claims.role === "admin" || claims.role === "tutor") {
            navigate("/admin#dashboard");
            // } 
            // else {
            //     setNotice("You are not authorized to access this website.");
            // }
        } else {
            setNotice("You entered a wrong username or password.");
        }
    }

    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href={`/css/${isRTL() ? "bootstrap.rtl.min.css" : "bootstrap.min.css"}`}
            />

            <div className="container">
                <div className="row justify-content-center mt-3 pt-3 pb-3">
                    {/* <div className="col-md-4 mt-3 pt-3 pb-3"> */}
                    <img src={logo} alt="Al-Sabah" style={{ width: "365px" }} />
                    {/* </div> */}
                </div>
                    <div className="row justify-content-center">
                    <div className="col-md-4 mt-3 pt-3 pb-3 text-center">
                    <MSLogin
                        tenant="0dde1a37-656b-47e8-b073-cfaf2ace179b"
                        onSuccess={handleSignin}
                        onError={handleError}
                    />
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-md-4 mt-3 pt-3 pb-3 align-items-center">
                        {"" !== notice &&
                            <div className="alert alert-warning" role="alert">
                                {notice}
                            </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function onMsalInstanceChange(msal: any) {
    throw new Error("Function not implemented.");
}
