import { useNavigate } from "react-router";
import logo from "../logo.png";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useLayoutDirection } from "../LayoutDirectionProvider";
import { AppBar, Box, IconButton, Link, Toolbar, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

export const NavigationBar = () => {
    const { currentUser, userRole, signOut } = useAuth();
    const { changeLanguage } = useLayoutDirection();
    const theme = useTheme();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const logout = async () => {
        await signOut();
        navigate("/");
    }

    return (
        <>
        <AppBar component="nav" position="fixed" sx={{display: 'flex', zIndex: theme.zIndex.drawer + 1}}>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ mr: 2, display: { sm: 'none' } }}
                    onClick={() => {}}
                >
                <MenuIcon />
                </IconButton>
                <Box component="img" src={logo} alt="Al-Sabah" sx={{ width: "135px" }} />
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, float: 'end', color: 'white' }}>
                    {t("welcome_msg2", { name: currentUser?.displayName })}&nbsp;&nbsp;&nbsp;&nbsp;
                
                    {i18n.language == "en" &&
                        <Link href="#" sx={{color: 'white'}} onClick={() => changeLanguage("ar")} className="lang-selector">
                            Ar
                        </Link>
                    }
                    {i18n.language == "ar" &&
                        <Link href="#" sx={{color: 'white'}} onClick={() => changeLanguage("en")} className="lang-selector">
                            En
                        </Link>
                    }
                    <Link href="#" sx={{color: 'white'}} onClick={logout}>
                        {t('logout')}
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
        </>
    );
}