import Visit from "../models/Visit";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { Box, TableCell, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";

interface VisitorLogFormProps {
    target: Visit,
}

export default function VisitorLogForm(props: VisitorLogFormProps) {
    const {
        target
    } = props;

    const { t } = useTranslation();
    const { currentUser, userRole } = useAuth();

    const [frontImage, setFrontImage] = useState<string>('');
    const [backImage, setBackImage] = useState<string>('');

    useEffect(() => {
        const frontImageRef = ref(storage, `/visitors_documents/${target.id}/front.png`)
        getDownloadURL(frontImageRef).then(url => {
            setFrontImage(url)
        })

        const backImageRef = ref(storage, `/visitors_documents/${target.id}/back.png`)
        getDownloadURL(frontImageRef).then(url => {
            setBackImage(url)
        })
        
    },[target])

    return (
        <Grid container spacing={2}>
            <Grid size={8}>
            <Table sx={{ 
                marginBottom: '25px',
                ['& .MuiTableCell-root']: { textAlign: 'start',},
            }}>
                <TableBody sx={{ 
                    ['tr:nth-of-type(even)']: {
                        backgroundColor: "white",
                    },
                    ['tr:nth-of-type(odd)']: {
                        backgroundColor: "#ececec",
                    },
                }}>
                    <TableRow>
                        <TableCell>
                            {t("category_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t(target.category)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("name_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("company_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.company ? target.company : '\u00A0'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("email_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.email ? target.email : '\u00A0'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("date_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {DateTime.fromJSDate(target.date).toFormat('dd/MM/yyyy hh:mm a')}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            {t("host_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.host ? target.host : '\u00A0'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("notes_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.notes ? target.notes : '\u00A0'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("checkedin_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.checkedInBy ? t('Checked in by ') + target.checkedInBy + ' @ ' + DateTime.fromJSDate(target.checkInDate!).toFormat('dd/MM/yyyy hh:mm a') : '\u00A0'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {t("checkedout_column_header")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {target.checkedOutBy ? t('Checked out by ') + target.checkedOutBy + ' @ ' + DateTime.fromJSDate(target.checkOutDate!).toFormat('dd/MM/yyyy hh:mm a') : '\u00A0'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </Grid>
            <Grid size={4}>
                <Typography component='h2' sx={{textAlign: 'center', backgroundColor: '#ececec', mb: 2}}>
                    {t('id_photo_label')}
                </Typography>
                <Box component='img' src={frontImage} sx={{'aspect-ratio:': 16/9, width: '100%' }} />
                <Box component='img' src={backImage} sx={{'aspect-ratio:': 16/9, width: '100%' }} />
            </Grid>
        </Grid>
    );
}