import { doc, collection, updateDoc, query, onSnapshot, where, orderBy, Timestamp } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import Visit, { VisitConverter } from "../../models/Visit";
import DeleteDialog from "../../components/delete-dialog";
import { useTranslation } from "react-i18next";
import InvitationForm from "../../components/InvitationForm";
import { DateTime, Settings } from "luxon";
import { DataGrid, GridEventListener, GridFilterModel, GridLogicOperator, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { FilterBox } from "../../components/FilterBox";
import { SearchBox } from "../../components/SearchBox";
import SavingIndicator from "../../components/saving-indicator";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCircleUser, faCrown, faPerson, faPersonWalking, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { VisitType } from "../../enums/VisitType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { escapeRegExp } from "@mui/x-data-grid/internals";
import { Box, Button, Drawer } from "@mui/material";


export default function InvitationsPage() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState<Visit[]>([]);
    const [filteredData, setFilteredData] = useState<Visit[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [count, setCount] = useState(0)
    const [showProgress, setShowProgress] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [target, setTarget] = useState<Visit | null>(null);

    const filterModel: GridFilterModel = {
        items: [
            { id: 1, field: 'name', operator: '>=', value: 'ن' },
            // { id: 2, field: 'isAdmin', operator: 'is', value: 'true' },
        ],
        logicOperator: GridLogicOperator.Or,
    };

    const handleDelete = async (row: any) => {
        const visitRef = doc(collection(db, "visits"), row.id)
        await updateDoc(visitRef, { deleted: true })
        setShowDeleteDialog(false)
        setShowProgress(false)
        setShowForm(false)
    }

    const handleClose = () => setShowForm(false);

    const applyFilter = (countryId: string, universityId: string, collegeId: string) => {

        // We need to save filters for later
        // setCountryFilter(countryId);
        // setUniversityFilter(universityId);
        // setCollegeFilter(collegeId);

        if (data) {
            const items = data.filter((course: Visit) => {
                // const collegeFilter = collegeId ? course.college?.id == collegeId : true;
                // const universityFilter = universityId ? course.college?.university?.id == universityId : true;
                // const countryFilter = countryId ? course.college?.university?.country?.id == countryId : true;

                // return collegeFilter && universityFilter && countryFilter;

                return true;
            })

            setFilteredData(items);
        }
    }

    const handleRowClick: GridEventListener<'rowClick'> = (params, event, details) => {
        setTarget(params.row)
        setSelectedRow(params.row)
        setShowForm(true)
    }

    const buildLeadingIcon = (params: GridRenderCellParams<any, any>) => {
        var icon: IconDefinition = faUnlink
        var color: string = 'text-primary'

        switch (params.value!) {
            case VisitType.vip:
                icon = faCrown
                break

            case VisitType.interview:
                icon = faCircleUser
                break

            case VisitType.visitor:
                icon = faPerson
                break

            case VisitType.walkin:
                icon = faPersonWalking
                break
        }

        return <FontAwesomeIcon icon={icon} size="xl" />
    }

    const searchData = (phrase: string) => {
        setSearchText(phrase);
        const searchRegex = new RegExp(escapeRegExp(phrase), 'i');
        const filteredRows = data.filter((row: any) => {
            // return Object.keys(row).some((field: any) => {
            return searchRegex.test(row['name'].toString())
            // });
        });

        setFilteredData(filteredRows)
        setCount(filteredRows.length)
    }

    useEffect(() => {
        Settings.defaultZone = 'Asia/Kuwait';

        setIsLoading(true);

        let dt = DateTime.fromISO(DateTime.now().setZone('Asia/Kuwait').toFormat('yyyy-MM-dd'));

        const q = query(collection(db, "visits").withConverter(VisitConverter),
            where('date', '>=', Timestamp.fromDate(dt.toJSDate())),
            where("deleted", "==", false),
            orderBy('date'));

        if (q) {
            const unsubscribe = onSnapshot(q, async (querySnapshot) => {
                let itemsArr: any = [];

                const promises = querySnapshot.docs.map(async (newDoc: any) => {
                    const newVisitor = { ...newDoc.data(), id: newDoc.id };
                    return newVisitor;
                });

                var newData = await Promise.all(promises);

                itemsArr.push(...newData);

                setData(itemsArr);
                setFilteredData(itemsArr);
                setCount(itemsArr.length)

                setLoading(false)
            })

            return () => unsubscribe();
        }
    }, [i18n.language])

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box component='h2' sx={{ mb: 4 }}>{t("invitations_title")}</Box>
                <Box sx={{ display: 'flex', float: 'end', mb: 3, py: 1, px: 3, border: 'solid 2px #74b3ce', borderRadius: 50, }}>
                    {t("expected_visitors_count", { count: count })}
                </Box>
            </Box>

            <div className="actions align-items-center" style={{ transition: 'padding-bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), 0.3s cubic-bezier(0.23, 1, 0.32, 1)', paddingBottom: '0px' }}>
                <div className="filters-container">
                    <SearchBox onChange={searchData} />
                    <FilterBox />
                    <div className="flex-grow"></div>
                    <Box sx={{ display: 'flex', float: 'end' }}>
                        <Button variant="contained"
                            sx={{ display: 'inline', px: 3 }}
                            onClick={() => { setTarget(null); setShowForm(true); }}>
                            {t("create_invitation_button_label")}
                        </Button>
                    </Box>
                </div>
            </div>

            <DataGrid
                loading={loading}
                disableColumnMenu={true}
                rows={(filteredData as unknown) as GridRowsProp[]}
                columns={[
                    {
                        field: "category",
                        headerName: "",
                        headerAlign: "center",
                        align: "center",
                        resizable: false,
                        width: 80,
                        renderCell: buildLeadingIcon,
                    },
                    { field: 'name', headerName: t("name_column_header"), headerAlign: 'center', flex: 1, align: 'right', resizable: false, cellClassName: 'data-column', },
                    { field: 'company', headerName: t("company_column_header"), headerAlign: 'center', flex: 1, align: 'right', resizable: false, cellClassName: 'data-column', },
                    { field: 'host', headerName: t("host_column_header"), headerAlign: 'center', flex: 1, align: 'right', resizable: false, cellClassName: 'data-column', },
                    {
                        field: 'date',
                        headerName: t("date_column_header"),
                        headerAlign: 'center', width: 150,
                        align: 'right',
                        resizable: false,
                        valueGetter: (value: any) => {
                            return DateTime.fromJSDate(value).toFormat('yyyy/MM/dd h:mm a')
                        }
                    },
                ]}
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton',
                    },
                }}
                sx={{
                    fontFamily: 'Zain',
                    fontSize: '0.9rem',
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                    },
                }}
                onRowClick={handleRowClick}
            />

            <DeleteDialog
                visible={showDeleteDialog}
                row={selectedRow}
                onConfirm={handleDelete}
                onHide={() => setShowDeleteDialog(false)}
            />

            <Drawer
                open={showForm}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '400px', p: '16px', paddingTop: '60px', display: 'flex', zIndex: 1400, position: 'absolute' }
                }}
            >
                <Box sx={{ mb: '16px', p: '5px', borderBottom: 'solid 1px #808080' }}>
                    {target?.name ?? t('register_visitor_title')}
                </Box>
                <InvitationForm
                    target={target}
                    onSubmit={() => setShowProgress(true)}
                    onSave={() => { setShowProgress(false); setShowForm(false) }}
                    onDelete={() => { setShowDeleteDialog(true) }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'black',
                        opacity: 0.5,
                        display: showProgress ? 'block' : 'none'
                    }}
                >
                    <SavingIndicator />
                </Box>
            </Drawer >
        </>
    )
}