import * as React from 'react';
// import { createTheme, extendTheme, ThemeProvider } from '@mui/material/styles';
import {
    createTheme as materialExtendTheme,
    ThemeProvider as MaterialThemeProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LayersIcon from '@mui/icons-material/Layers';
import { useTranslation } from 'react-i18next';
import { Box, CssBaseline, Drawer, Toolbar } from '@mui/material';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import InvitationsPage from './pages/invitations/InvitationsPage';
import VisitorsLogPage from './pages/visitors_log/VisitorsLogPage';
import { NavigationBar } from './components/NavigationBar';
import { SideBar } from './components/Sidebar';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

interface LayoutProps {
    children?: React.ReactNode
}

function DemoPageContent({ pathname }: { pathname: string }) {
    const renderSwitch = (param: string) => {
        switch (param) {
            case '/dashboard':
                return <DashboardPage />;
            case '/invitations':
                return <InvitationsPage />;
            case '/log':
                return <VisitorsLogPage />;
            default:
                return 'foo';
        }
    }

    return (
        <Box>
            {renderSwitch(pathname)}
        </Box>
    );
}

export const Layout = (props: LayoutProps) => {
    const { i18n } = useTranslation();

    const [pathname, setPathname] = React.useState('/dashboard');

    const { t } = useTranslation();

    const drawerWidth = 240;

    const appTheme = materialExtendTheme({
        colorSchemes: { light: true, dark: true },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        direction: 'rtl',
        typography: {
            allVariants: {
                fontFamily: '"Zain", "Roboto", "Helvetica", "Arial", sans-serif',
                // fontSize: 14,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    contained: {
                        fontFamily: 'Zain'
                    }
                }
            }
        }
    });

    return (
        <MaterialThemeProvider theme={{ [MATERIAL_THEME_ID]: appTheme }}>
            <JoyCssVarsProvider>
                <CssBaseline enableColorScheme />
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <NavigationBar />
                    <Drawer
                        variant="permanent"
                        anchor={i18n.language == 'en' ? 'left' : 'right'}
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            display: { xs: 'none', md: 'flex' },
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                        }}
                    >
                        <Toolbar />
                        <SideBar onLinkClick={(path) => setPathname(path)} />
                    </Drawer>
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Toolbar />
                        <DemoPageContent pathname={pathname} />
                    </Box>
                </Box>
            </JoyCssVarsProvider>
        </MaterialThemeProvider >
    );
}
