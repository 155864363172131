import { DocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";
import { VisitType } from "../enums/VisitType";
import { DateTime } from "luxon";

export default class Visit {
    id?: string | null;
    category: VisitType = VisitType.visitor;
    name: string = "";
    company?: string | null;
    email?: string | null;
    date: Date = new Date();
    host?: string | null;
    notes?: string | null;
    checkInDate?: Date | null;
    checkOutDate?: Date | null;
    checkedInBy?: string | null;
    checkedOutBy?: string | null;
    deleted?: boolean;
    timeCreated?: Date;
    timeModified?: Date;

    constructor() { }
}

export const VisitConverter = {
    toFirestore: (visit: Visit) => {
        return {
            category: visit?.category,
            name: visit?.name,
            email: visit?.email,
            company: visit?.company,
            host: visit?.host,
            notes: visit?.notes,
            date: Timestamp.fromDate(visit?.date),
            checkInDate: visit?.checkInDate ? Timestamp.fromDate(visit?.checkInDate) : null,
            checkOutDate: visit?.checkOutDate ? Timestamp.fromDate(visit?.checkOutDate) : null,
            checkedInBy: visit?.checkedInBy,
            checkedOutBy: visit?.checkedOutBy,
            deleted: visit?.deleted,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let visit = new Visit();
        visit.id = snapshot.id;
        visit.category = data?.category;
        visit.name = data?.name;
        visit.email = data?.email;
        visit.company = data?.company;
        visit.host = data?.host;
        visit.date = data?.date.toDate();
        visit.checkInDate = data?.checkInDate?.toDate();
        visit.checkOutDate = data?.checkOutDate?.toDate();
        visit.checkedInBy = data?.checkedInBy;
        visit.checkedOutBy = data?.checkedOutBy;
        visit.notes = data?.notes;
        visit.deleted = data?.deleted;

        if (data?.timeCreated) {
            visit.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            visit.timeModified = data?.timeModified.toDate();
        }

        return visit;
    }
};