import React, { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../contexts/AuthContext";
import { Layout } from "../Layout";

interface PrivateRouteProps {
    children: ReactNode,
};

const PrivateRoute = ({ children = <></>, ...rest }) => {

    const { currentUser } = useAuth();

    return currentUser ? (
        <Layout>
            <Outlet />
        </Layout>
    ) : <Navigate to="/login" />;
};

export default PrivateRoute;
