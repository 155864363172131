import { useTranslation } from "react-i18next";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Range } from 'react-date-range';
import { useState } from "react";
import { DateTime } from "luxon";

export const FilterBox = () => {
    const { t } = useTranslation();

    const [state, setState] = useState<Range[]>([
        {
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
          key: 'selection'
        }
    ]);
      
    return (
    <div className="outer-container">
        {/* <DateRangePicker
            onChange={item => setState([item.selection])}
            // showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
        /> */}
        <div className="ms-container">
            <button type="button" className="ms-button ms-button--primary ms-button--large is-text shortcut-button">
                <span className="">{t('all')}</span>
            </button>
            <div
                className="ms-date-editor ms-date-editor--daterange ms-input__wrapper ms-range-editor ms-range-editor--large ms-tooltip__trigger ms-tooltip__trigger">
                <i className="ms-icon ms-input__icon ms-range__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                        <path fill="currentColor"
                            d="M128 384v512h768V192H768v32a32 32 0 1 1-64 0v-32H320v32a32 32 0 0 1-64 0v-32H128v128h768v64H128zm192-256h384V96a32 32 0 1 1 64 0v32h160a32 32 0 0 1 32 32v768a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32h160V96a32 32 0 0 1 64 0v32zm-32 384h64a32 32 0 0 1 0 64h-64a32 32 0 0 1 0-64zm0 192h64a32 32 0 1 1 0 64h-64a32 32 0 1 1 0-64zm192-192h64a32 32 0 0 1 0 64h-64a32 32 0 0 1 0-64zm0 192h64a32 32 0 1 1 0 64h-64a32 32 0 1 1 0-64zm192-192h64a32 32 0 1 1 0 64h-64a32 32 0 1 1 0-64zm0 192h64a32 32 0 1 1 0 64h-64a32 32 0 1 1 0-64z">
                        </path>
                    </svg>
                </i>
                <input autoComplete="off" name="" className="ms-range-input" defaultValue={new Date().toDateString()} readOnly />
                <span className="ms-range-separator">-</span>
                <input autoComplete="off" name="" className="ms-range-input" defaultValue={new Date().toDateString()} readOnly />
                <i className="ms-icon ms-input__icon ms-range__close-icon ms-range__close-icon--hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                        <path fill="currentColor"
                            d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248L466.752 512z">
                        </path>
                        <path fill="currentColor"
                            d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z">
                        </path>
                    </svg>
                </i>
            </div>
        </div>
    </div>
    )
}