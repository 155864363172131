import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler, SubmitErrorHandler, Controller } from "react-hook-form";
import { z } from "zod";
import Visit from "../models/Visit";
import { VisitorSchema } from "../services/forms-schemas";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { VisitType } from "../enums/VisitType";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { db, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Box, Button, Input, Link, MenuItem, Select, TextField } from "@mui/material";
import Textarea from '@mui/joy/Textarea';


interface InvitationFormProps {
    target: Visit | null,
    onSubmit?(): void,
    onSave?(): void,
    onDelete?(id: string): void,
}

export default function InvitationForm(props: InvitationFormProps) {
    const {
        target = null,
    } = props;

    const { t } = useTranslation();
    const { currentUser, userRole } = useAuth();

    type Inputs = z.infer<typeof VisitorSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(VisitorSchema)
        });

    const processForm: SubmitHandler<Inputs> = async data => {
        const objectData = {
            category: data.category,
            name: data.name,
            company: data.company ?? null,
            email: data.email ?? null,
            host: data.host,
            notes: data.notes,
            date: data.date,
            deleted: false,
        };

        if (props.onSubmit) {
            props.onSubmit()
        }

        try {
            let visit = { ...objectData, id: target?.id }
            const modified = JSON.stringify(target) !== JSON.stringify(visit)

            if (target) {
                if (modified) {
                    await setDoc(doc(db, "visits", target?.id ?? ''), objectData)
                }
            } else {
                const newVisit = await addDoc(collection(db, 'visits'), objectData)
                visit.id = newVisit.id
            }

            // If there is no change we don't resend invitation
            if (modified) {
                sendInvitation(visit)
            } else {
                if (props.onSave) {
                    props.onSave()
                }
            }
        } catch (e) {
            console.log(e);
            //  setError("Error!!");
            //  setLoading(false);
        }

        reset();
    }

    const sendInvitation = (visit: any) => {
        const callableSendInvitation = httpsCallable(functions, 'sendInvitation');
        callableSendInvitation({ visit: visit }).then((result) => {
            if (props.onSave) {
                props.onSave()
            }
        }).catch((error) => {
            console.log(`error: ${JSON.stringify(error)}`);
        })
    }

    const handleFormErrors: SubmitErrorHandler<Inputs> = errors => {
        console.log(errors);
    }

    // const theme = createTheme({
    //     components: {
    //       MuiTextField: {
    //         styleOverrides: {
    //           root: {
    //             '& label': {
    //               margin: '2rem',
    //             },
    //           },
    //         },
    //       },
    //     },
    //     typography: {
    //       "fontFamily": `"Zain", "Roboto", "Helvetica", "Arial", sans-serif`,
    //       "fontSize": 14,
    //       "fontWeightLight": 300,
    //       "fontWeightRegular": 400,
    //       "fontWeightMedium": 500,
    //     }
    //   });

    useEffect(() => {
        reset()

        if (target) {
            setValue('date', target?.date)
        }

    }, [target])

    return (
        <>
            <Box
                sx={{
                    '.MuiFormControl-root': { display: 'block', width: '100%', marginTop: '16px', },
                    '.MuiTextarea-root': { marginTop: '16px', },
                }}
            >
                <Input
                    type="hidden"
                    {...register("id", { value: target?.id ?? undefined })}
                />
                <Select
                    id="category"
                    label={t("category_column_header")}
                    multiple={false}
                    fullWidth
                    defaultValue={target?.category ?? 'visitor'}
                    {...register("category", { value: target?.category, required: true })}
                >
                    {Object.keys(VisitType).map((key) => (
                        key !== 'walkin' ?
                            <MenuItem key={key} value={key}>{t(key)}</MenuItem>
                            : null
                    ))
                    }
                </Select>
                {errors.category?.message && (
                    <p className="small text-danger">{errors.category.message}</p>
                )}

                <TextField
                    id="name"
                    fullWidth
                    label={t("name_column_header")}
                    defaultValue={target?.name}
                    error={errors.name?.message !== undefined ?? false}
                    helperText={errors.name?.message}
                    {...register("name", { value: target?.name, required: true })}
                />
                <TextField
                    id="company"
                    fullWidth
                    label={t("company_column_header")}
                    defaultValue={target?.company}
                    {...register("company", { value: target?.company ?? null, required: false })}
                />

                <TextField
                    id="email"
                    fullWidth
                    label={t("email_column_header")}
                    error={errors.date?.message !== undefined ?? false}
                    helperText={errors.date?.message}
                    defaultValue={target?.email}
                    {...register("email", { value: target?.email ?? null, required: false })}
                />

                <Controller
                    control={control}
                    name="date"
                    rules={{ required: true }}
                    defaultValue={DateTime.now().toJSDate()}
                    render={({ field }) => {
                        return (
                            <DateTimePicker
                                label={t("date_column_header")}
                                disablePast
                                value={DateTime.fromJSDate(field.value)}
                                minDate={DateTime.fromFormat(DateTime.now().setZone('Asia/Kuwait').toFormat('yyyy-MM-dd'), 'yyyy-MM-dd')}
                                inputRef={field.ref}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                    },
                                }}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                            />
                        );
                    }}
                />
                {errors.date?.message && (
                    <p className="small text-danger">{errors.date.message}</p>
                )}

                <TextField
                    id="host"
                    fullWidth
                    label={t("host_column_header")}
                    defaultValue={target?.host}
                    {...register("host", { value: target?.host ?? null, required: false })}
                />
                {errors.host?.message && (
                    <p className="small text-danger">{errors.host.message}</p>
                )}

                <Controller
                    control={control}
                    name="notes"
                    rules={{ required: false }}
                    defaultValue={target?.notes ?? ''}
                    render={({ field: { onChange, value, ref } }) => {
                        return (
                            <Textarea
                                minRows={4}
                                value={value ?? ''}
                                onChange={onChange}
                            />
                        )
                    }} />

                <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '16px' }}>
                    <Button
                        variant="contained"
                        sx={{ marginInlineEnd: '10px' }}
                        onClick={handleSubmit(processForm, handleFormErrors)}
                    >
                        {t("save_button_label")}
                    </Button>
                    {target && target.email &&
                        <Button
                            variant="outlined"
                            onClick={() => { sendInvitation(target) }}>
                            {t("resend_invitation_button_label")}
                        </Button>
                    }
                </Box>
                {target &&
                    <Box sx={{display: 'felx', marginTop: '48px', clear: 'both', justifyContent: 'center', alignItems: 'center' }}>
                        <Link href="#"
                            className="text-danger"
                            underline="none"
                            onClick={(e) => {
                                if (props.onDelete)
                                    props.onDelete(target.id ?? '')
                        }}>
                            <HighlightOffIcon sx={{color: 'red', me: 1}} />
                            {t('delete_button_label')}
                        </Link>
                    </Box>
                }
            </Box>
            <Box sx={{ height: '100px' }}></Box>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
        </>
    );
}