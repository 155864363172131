import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface DeleteDialogProps {
    row?: any,
    visible: boolean,
    note?: string,
    onHide?(): void,
    onConfirm?(row: any): void,
};

export default function DeleteDialog(props: DeleteDialogProps) {

    const [visible, setVisible] = useState(props.visible);
    const { t } = useTranslation();

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={visible}

        >
            <DialogTitle>{t("warning_title")}</DialogTitle>
            <DialogContent dividers>
                <div>
                    {t("delete_dialog_message")}
                    <div className="text-danger">{props.note}</div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ backgroundColor: 'red', marginInlineEnd: 1 }} onClick={() => props.onConfirm && props.onConfirm(props.row)}>{t("delete_button_label")}</Button>
                <Button autoFocus onClick={() => props.onHide && props.onHide()}>
                    {t("cancel_button_label")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
