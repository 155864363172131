import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from './Login';
import './App.css';
import InvitationsPage from './pages/invitations/InvitationsPage';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { auth } from './firebase';
import { User } from 'firebase/auth';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { useTranslation } from 'react-i18next';
import HomePage from './pages/home/HomePage';
import VisitorsLogPage from './pages/visitors_log/VisitorsLogPage';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { createTheme, ThemeProvider } from '@mui/material';

function App() {
  const { i18n } = useTranslation();

  const [user, setUser] = useState<User | null>(null);

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label': {
              margin: '2rem',
            },
          },
        },
      },
    },
    typography: {
      "fontFamily": `"Zain", "Roboto", "Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500,
    }
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return unsubscribe;
  }, [i18n.language]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='ar-KW'>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin">
              <Route index element={<PrivateRoute />} />
              <Route path="dashboard" element={<PrivateRoute />}>
                <Route path="" element={<DashboardPage />} />
              </Route>
              <Route path="invitations" element={<PrivateRoute />}>
                <Route path="" element={<InvitationsPage />} />
              </Route>
              <Route path="log" element={<PrivateRoute />}>
                <Route path="" element={<VisitorsLogPage />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </LocalizationProvider>
  )
}

export default App;
