// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from "firebase/app-check";

const useEmulators = process.env.REACT_APP_USE_EMULATORS === "true";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// declare global {
//     // eslint-disable-next-line no-var
//     var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
//   }

// // window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// window.FIREBASE_APPCHECK_DEBUG_TOKEN = 'C84B2325-1565-4F0B-9D28-C2CD9E32929A';

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_KEY as string),
//     isTokenAutoRefreshEnabled: true
// });

// getToken(appCheck)
//     .then(() => {
//         console.log('Passed AppCheck')
//     })
//     .catch((error) => {
//         console.log(error.message)
//     })

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// Use emulators
if (useEmulators) {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectStorageEmulator(storage, 'localhost', 9199);
    connectFunctionsEmulator(functions, "localhost", 5001);
}