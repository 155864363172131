import { z } from "zod";

export const VisitorSchema = z.object({
    id: z.string().optional(),
    category: z.string().min(1, "Category is required"),
    name: z.string().min(1, "Name is required"),
    company: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    notes: z.string().nullable(),
    host: z.string().nullable(),
    // date: z.string().datetime().min(1, "Date is required"),
    date: z.coerce.date(),
    // time: z.string().min(1, "Arrival Time is required"),
    checkInDate: z.coerce.date().nullable().optional(),
    checkOutDate: z.coerce.date().nullable().optional(),
})