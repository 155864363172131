import { InputAdornment, TextField } from "@mui/material";
import { HtmlHTMLAttributes, useRef } from "react";
import { useTranslation } from "react-i18next";

interface SearchBoxProps {
    onChange?: ((phrase: string) => void),
}

export const SearchBox = (props: SearchBoxProps) => {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className="so-input so-input--large so-input--prefix so-input--suffix search">
            {/* <TextField 
                label="Search..."
                id="standard-start-adornment"
                sx={{ m: 1, width: '25ch' }} 
                slotProps={{
                    textField: {
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                   ...
                                </InputAdornment>
                            ),
                        },
                    },
                    inputAdorment: {
                        position: "start",
                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    },
                  }}
                  variant="outlined"
            /> */}
            <div className="so-input__wrapper" tabIndex={-1}>
                <span className="so-input__prefix">
                    <span className="so-input__prefix-inner">
                        <svg data-v-258dc82a="" className="svg-inline--fa fa-magnifying-glass" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path className="" fill="currentColor" d="M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z">
                            </path>
                        </svg>
                    </span>
                </span>
                <input
                    ref={inputRef}
                    className="so-input__inner"
                    type="text"
                    autoComplete="off"
                    tabIndex={0}
                    placeholder={t('search')}
                    onChange={(e) => props.onChange && props.onChange!(e.currentTarget.value)} />
            </div>
        </div>
    )
}