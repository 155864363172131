import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faInbox,
    faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Box, Link } from "@mui/material";

interface SideBarProps {
    onLinkClick?(path: string): void,
}
export const SideBar = (props: SideBarProps) => {
    const location = useLocation();
    const { userRole } = useAuth();

    const { t } = useTranslation();

    // const links = userRole === "admin" ? [
    //     { name: t('home'), href: '', icon: faHome },
    //     { name: t('invitations'), href: '/admin/invitations', icon: faPerson },
    //     { name: t('visitors_log'), href: '/admin/log', icon: faPerson },
    // ] : [
    //     { name: t('home'), href: '/admin/dashboard', icon: faHome },
    //     { name: t('invitations'), href: '/admin/invitations', icon: faInbox },
    //     { name: t('visitors_log'), href: '/admin/log', icon: faPerson },
    // ];

    return (
        <Box component="nav" height="100%">
            <Box paddingTop="24px" paddingX="8px">
                <Link
                    underline="none"
                    display="block"
                    marginBottom="8px"
                    paddingY="5px"
                    href='#dashboard'
                    onClick={() => props.onLinkClick && props.onLinkClick('/dashboard')}
                    className={`${location.pathname.startsWith('/admin/dashboard') ? "active" : ""}`}>
                    <FontAwesomeIcon icon={faHome} style={{ marginInlineEnd: '5px' }} />
                    <span>{t('home')}</span>
                </Link>
                <Link
                    underline="none"
                    display="block"
                    marginBottom="8px"
                    paddingY="5px"
                    href='#invitations'
                    onClick={() => props.onLinkClick && props.onLinkClick('/invitations')}
                    className={`${location.pathname.startsWith('/admin/invitations') ? "active" : ""}`}>
                    <FontAwesomeIcon icon={faInbox} style={{ marginInlineEnd: '5px' }} />
                    <span>{t('invitations')}</span>
                </Link>
                <Link
                    underline="none"
                    display="block"
                    marginBottom="8px"
                    paddingY="5px"
                    href='#log'
                    onClick={() => props.onLinkClick && props.onLinkClick('/log')}
                    className={`${location.pathname.startsWith('/admin/log') ? "active" : ""}`}>
                    <FontAwesomeIcon icon={faPerson} style={{ marginInlineEnd: '5px' }} />
                    <span>{t('visitors_log')}</span>
                </Link>
            </Box >
            <Box position="relative" zIndex="0" bottom="0px">v {process.env.REACT_APP_VERSION}</Box>
        </Box>
    )
}
